<template>
  <div>
    <sidebar-search-product
      v-if="reason !== 'UNDO_RECEIVED' && !stockAdjustmentNoteId && !isMobile"
      class="print-dp-none"
      :status="status"
      @productSelecting="productSelecting($event)">
      <template v-slot:header>
        <h6 class="text-h6">
          Withdraw Note
        </h6>
        <p class="text-subtitle-1 mb-0">
          เลือกสินค้าเพื่อออกใบนำสินค้าออก
        </p>
      </template>
    </sidebar-search-product>
    <v-app-bar
      id="withdrawnote-bar"
      class="print-dp-none"
      app>
      <div class="app-bar-title">
        <v-btn
          v-if="isDraft"
          color="primary"
          :disabled="isDisableCreateBtn || isDisabledByRole"
          small
          @click="submitNote()">
          Create
        </v-btn>
        <v-btn
          v-if="isPending && !isMobile"
          color="error"
          small
          :disabled="isDisabledByRole"
          @click="tryToUpdateNote('cancel')">
          ยกเลิกใบนำออก
        </v-btn>

        <v-btn
          v-if="isPending"
          fab
          icon
          dark
          small
          color="secondary"
          @click="showRFIDModal = true">
          <v-icon>mdi-cube-scan</v-icon>
        </v-btn>
        <v-text-field
          v-if="isPending"
          v-model="productQr"
          class="mx-2"
          autofocus
          placeholder="คลิ๊กที่ช่องนี้เพื่อสแกน QR Code"
          hide-details
          dense
          solo
          @keydown.enter="enterQr" />
        <v-spacer v-if="!isPending"></v-spacer>
        <div v-if="isMobile">
          <v-menu
            v-if="isPending || isDraft"
            offset-y>
            <template #activator="{ on, attrs }">
              <v-btn
                fab
                icon
                dark
                small
                color="secondary"
                v-bind="attrs"
                v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-if="isDraft"
                link
                :disabled="isDisableCreateBtn || isDisabledByRole"
                @click="submitNote()">
                <v-list-item-title>
                  สร้าง
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="isPending"
                link
                :disabled="!isPending || isDisabledByRole"
                @click="submitNote('pending')">
                <v-list-item-title>
                  บันทึก
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="isPending && isShowSetcount"
                link
                :disabled="isDisabledByRole"
                @click="setCount()">
                <v-list-item-title>
                  Set Count
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="isPending"
                link
                :disabled="isDisableBtnApprove || isDisabledByRole || !isFormValid"
                @click="approve()">
                <v-list-item-title>
                  Approve
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="isPending && !isMobile"
                link
                :disabled="isDisabledByRole"
                @click="tryToUpdateNote('cancel')">
                <v-list-item-title>
                  ยกเลิกใบนำออก
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <template v-else>
          <v-btn
            v-if="isPending"
            :disabled="!isPending || isDisabledByRole"
            small
            color="success"
            @click="submitNote('pending')">
            บันทีก
          </v-btn>
          <v-btn
            v-if="isPending && isShowSetcount"
            small
            :disabled="isDisabledByRole"
            @click="setCount()">
            Set Count
          </v-btn>
          <v-btn
            v-if="isPending"
            :disabled="isDisableBtnApprove || isDisabledByRole"
            color="success"
            small
            @click="approve()">
            Approve
          </v-btn>
          <v-btn
            v-if="!isBlank && !isDraft && !isCanceled"
            small
            @click="print()">
            <v-icon class="mr-2">
              mdi-printer
            </v-icon>
            <span>Print</span>
          </v-btn>
          <v-btn
            v-if="isDraft"
            color="success"
            small
            @click="showImportDialog()">
            Import
          </v-btn>
        </template>
      </div>
      <!-- <template
        v-if="isMobile && isPending"
        v-slot:extension>
        <v-text-field
          v-model="productQr"
          autofocus
          placeholder="คลิ๊กที่ช่องนี้เพื่อสแกน QR Code"
          hide-details
          dense
          solo
          @keydown.enter="enterQr" />
      </template> -->
    </v-app-bar>
    <v-main>
      <v-form
        ref="form"
        v-model="isFormValid"
        @input="inputValidate($event)">
        <page-a4
          :note-id="noteCode"
          :products="productsSelected"
          :status="status"
          :note-ref="noteRef"
          :print-by="printBy"
          :qr="qr"
          :create-by="createBy"
          :warehouse="warehouseSelected"
          :print-time="printTime"
          :out-of-limit="outOfLimit"
          :note.sync="note"
          note-type="withdrawNote"
          :transfer-warehouse-id.sync="transferWarehouseId"
          :reason.sync="reason"
          :warehouse-items="warehouseItems"
          :update-by="updateBy"
          :update-time="updateTime"
          :stock="stock"
          :count="count"
          :is-mobile="isMobile"
          @remove-product="removeProduct($event)"
          @remove-sku="removeSku($event)">
          <template v-slot:title>
            <div class="page-a4-title">
              <h1 class="mr-2">
                ใบนำสินค้าออก
              </h1>
            </div>
          </template>
        </page-a4>
      </v-form>
    </v-main>
    <v-dialog
      v-model="showImport"
      persistent
      width="80vw"
      max-width="450px">
      <v-card>
        <v-card-title class="headline">
          <label>Import CSV</label>
          <v-spacer />
          <v-btn
            icon
            @click="showImport = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="d-flex flex-column justify-center align-center mt-4">
            <img
              src="@/assets/image/import-csv-example.png"
              alt="Import csv example" />
            <p>
              ตัวอย่างไฟล์ที่สามารถ Upload ได้ (นามสกุล csv)
            </p>

            <div style="width: 80%;">
              <csv-reader
                label="เลือกไฟล์ .csv"
                @input="onSelectCsv($event)" />
            </div>

            <div
              v-if="invalidData.length"
              style="width: 100%;">
              <p class="mt-4 mb-2 font-weight-bold">
                รายการที่ Error
              </p>
              <v-data-table
                :headers="headers"
                :items="invalidData"
                :items-per-page="-1"
                hide-default-footer
                class="red--text">
              </v-data-table>
            </div>
          </div>
        </v-card-text>
        <v-card-actions
          v-if="isReaded && !invalidData.length"
          class="justify-center align-center pb-6">
          <v-btn
            color="success"
            @click="onConfirmCsv()">
            ยืนยัน
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <RFIDScanner
      v-model="showRFIDModal"
      :items="productsSelected"
      :alias-ids="aliasIds"
      :compare="status !== 'draft'"
      @confirm="onScanned($event)" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { getAuthDecode, getRole } from '@/assets/js/Authentication'
import ProductProvider from '@/resources/ProductProvider'
import InventoryProvider from '@/resources/InventoryProvider'
import SizeValues from '@/assets/js/SizeValues'
import { generateQRCode } from '@/assets/js/helper'
import CsvReader from '@/views/management/components/CsvReader.vue'
import SidebarSearchProduct from '../components/SidebarSearchProduct.vue'
import PageA4 from '../components/PageA4.vue'
import RFIDScanner from '../components/RFIDScanner.vue'
import StockCountAdjustmentProvider from '@/resources/StockCountAdjustmentProvider'

const InventoryService = new InventoryProvider()
const ProductService = new ProductProvider()
const stockCountAdjustmentService = new StockCountAdjustmentProvider()

export default {
  components: {
    sidebarSearchProduct: SidebarSearchProduct,
    pageA4: PageA4,
    CsvReader,
    RFIDScanner
  },
  data () {
    return {
      headers: [
        {
          text: 'Row',
          value: 'row'
        },
        {
          text: 'SKU',
          value: 'sku'
        },
        {
          text: 'QTY',
          value: 'qty'
        }
      ],
      productQr: '',
      productsSelected: [],
      status: '',
      printBy: getAuthDecode().email,
      reason: null,
      noteCode: '-',
      createBy: '-',
      note: '',
      noteRef: null,
      noteWarehouse: 0,
      qr: null,
      printTime: new Date().toString(),
      updateBy: '-',
      updateTime: new Date().toString(),
      loading: false,
      stock: [],
      validateFormTimeout: null,
      valid: false,
      transferWarehouseId: null,
      ref: null,
      isFormValid: false,
      showImport: false,
      isReaded: false,
      validData: [],
      invalidData: [],
      showRFIDModal: false,
      aliasIds: {},
      stockAdjustmentNote: null,
      saveClicked: false
    }
  },
  computed: {
    ...mapGetters({
      warehouse: 'Store/warehouse',
      storeSetting: 'Store/storeSetting'
    }),
    isShowSetcount () {
      const role = getRole()
      const setCountRoles = [
        'auth',
        'management',
        'area_manager',
        'developer',
        'merchandising_planner',
        'inventory_and_costing',
        'merchandiser',
        'warehouse_manager',
        'warehouse'
      ]
      return setCountRoles.some((r) => r === role)
    },
    isDisabledByRole () {
      const dnRole = [
        'management',
        'area_manager',
        'marketing',
        'developer',
        'creative',
        'merchandising_planner',
        'merchandiser',
        'online_admin',
        'accounting_manager',
        'warehouse_manager',
        'warehouse',
        'vm',
        'store_manager',
        'acting_assist_store_manager',
        'inventory_and_costing',
        'assist_store_manager',
        'sales_staff'
      ]

      const sampleRoles = [
        'developer',
        'inventory_and_costing',
        'creative'
      ]

      const role = getRole()

      if (this.reason === 'SAMPLE') {
        return !sampleRoles.includes(role)
      }

      return !dnRole.some((r) => r === role)
    },
    isTransferNote () {
      return this.transferWarehouseSelected && this.transferWarehouseSelected.id > 0
    },
    isDisableCreateBtn () {
      if (this.reason === 'STOCK_COUNT') {
        return !this.valid || this.productsSelected.length === 0
      }

      let transfer = this.isTransferNote ? !!this.transferWarehouseId : true
      if (this.reason === 'DEFECT' || this.reason === 'UNDO_RECEIVED' || this.reason === 'SAMPLE') {
        transfer = true
      }
      return !this.valid || this.productsSelected.length === 0 || !transfer || this.reason === null
    },
    isDisableSaveDraft () {
      return !this.valid || this.productsSelected.length === 0
    },
    warehouseItems () {
      return this.warehouse.filter((r) => r.id !== this.warehouseId && r.id !== 0)
    },
    warehouseSelected () {
      const id = this.warehouseId || 0
      return this.findWarehouse(id)
    },
    transferWarehouseSelected () {
      const id = this.transferWarehouseId || 0
      return this.findWarehouse(id)
    },
    warehouseId () {
      let warehouseId = 0
      if (this.noteWarehouse) {
        warehouseId = this.noteWarehouse
      } else if (this.$route.query.warehouse) {
        warehouseId = parseInt(this.$route.query.warehouse)
      }
      return warehouseId
    },
    noteId () {
      return this.$route.query.id || null
    },
    isDraft () {
      return this.status === 'draft'
    },
    isPending () {
      return this.status === 'pending'
    },
    isCanceled () {
      return this.status === 'canceled'
    },
    isApproved () {
      return this.status === 'approved'
    },
    isBlank () {
      return this.status === ''
    },
    count () {
      return this.productsSelected.reduce((total, p) => total + p.skus.reduce((sum, s) => sum + Number(s.count), 0), 0)
    },
    outOfLimit () {
      return this.productsSelected.some((pd) => !!pd.skus.some((sku) => sku.amount < sku.count))
    },
    isDisableBtnApprove () {
      return this.count <= 0 || this.outOfLimit
    },
    deliveryNoteId () {
      return this.$route.query.dnNo || null
    },
    isMobile () {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm
    },
    stockAdjustmentNoteId () {
      return this.$route.query.stockAdjustmentNoteId || null
    }
  },
  watch: {
    reason (val) {
      if (val === 'DEFECT' || val === 'UNDO_RECEIVED' || val === 'SAMPLE') {
        this.transferWarehouseId = null
      }
    }
  },
  created () {
    this.initials()
  },
  beforeDestroy () {
    if (!this.saveClicked && this.stockAdjustmentNoteId) {
      this.stockCountAdjustmentService.updateAdjustmentNoteStatus({ stockAdjustmentNoteId: this.stockAdjustmentNoteId, status: 'inactive' })
    }
    this.removeListener()
    window.removeEventListener('afterprint', this.handleAfterPrint)
  },
  mounted () {
    window.addEventListener('afterprint', this.handleAfterPrint)
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading'
    }),
    initials () {
      window.onbeforeunload = () => 'Changes you made may not be saved.'
      window.onbeforeprint = () => {
        this.printTime = new Date().toString()
      }
      if (this.noteId) {
        this.saveClicked = true
        this.fetchNote()
      } else if (this.deliveryNoteId) {
        this.getDeliveryNote()
      } else if (this.stockAdjustmentNoteId) {
        this.status = 'draft'
        this.fetchStockAdjustmentNote(this.stockAdjustmentNoteId)
      } else {
        this.status = 'draft'
      }
    },
    async onScanned (items) {
      if (this.status === 'draft') {
        const codes = items.map((v) => v.code)
        const itemsInNote = this.productsSelected.filter((v) => v.skus.some((s) => codes.includes(s.code)))
        const codesInNote = itemsInNote.reduce((arr, i) => [...arr, ...i.skus.map((v) => v.code)], [])
        const currentItems = items.filter((v) => codesInNote.includes(v.code))
        const newItems = items.filter((v) => !codesInNote.includes(v.code))

        if (currentItems.length) {
          currentItems.forEach((item) => {
            const index = this.productsSelected.findIndex((v) => v.skus.some((s) => s.code === item.code))
            const skuIndex = this.productsSelected[index].skus.findIndex((v) => v.code === item.code)

            this.productsSelected[index].skus[skuIndex].amount += item.count
          })
        }

        if (newItems.length) {
          const { products, skus } = await this.getProductByCodes(newItems.map((v) => v.code))
          const selected = []

          newItems.forEach((v) => {
            const sku = skus[v.code] || null

            if (sku) {
              const product = products[sku.productId]

              selected.push({
                id: product.id,
                name: product.name,
                model: product.model,
                brand: product.brand,
                variant: sku.variant,
                photoUrls: product.photoUrls,
                skus: [
                  {
                    ...sku,
                    amount: Number(v.count)
                  }
                ]
              })
            }
          })

          // this.productsSelected = []
          selected.forEach((v) => this.productSelecting(v))
        }
      } else {
        for (const item of items) {
          for (let i = 0; i < this.productsSelected.length; i++) {
            const index = this.productsSelected[i].skus.findIndex((sku) => sku.code === item.code)

            if (index !== -1) {
              this.productsSelected[i].skus[index].count += item.count
            }
          }
        }
      }

      this.showRFIDModal = false
    },
    showImportDialog () {
      this.validData = []
      this.invalidData = []
      this.isReaded = false
      this.showImport = true
    },
    onSelectCsv (data) {
      if (!data) {
        this.validData = []
        this.invalidData = []
        this.isReaded = false
        return
      }

      const validateFields = [
        {
          name: 'sku',
          type: 'string'
        },
        {
          name: 'qty',
          type: 'number'
        }
      ]

      const headerMatch = validateFields.every((v) => data.headers.includes(v.name))

      if (data.headers.length !== validateFields.length || !headerMatch) {
        this.setSnackbar({
          value: true,
          message: 'หัวตารางต้องมีแค่ sku และ qty เท่านั้น',
          type: 'error'
        })
        return
      }

      data.results.forEach((v, i) => {
        const valid = validateFields.every((f) => {
          if (f.type === 'number') {
            return f.name in v && !Number.isNaN(v[f.name]) && !Number.isNaN(parseInt(v[f.name]))
          }

          return f.name in v && v[f.name] === String(v[f.name])
        })

        const index = this.validData.findIndex((d) => d.sku === v.sku)

        if (valid && index === -1) {
          this.validData.push({
            ...v,
            qty: Number(v.qty)
          })
        } else if (valid) {
          this.validData[index].qty += Number(v.qty)
        } else {
          this.invalidData.push({
            row: i + 2,
            ...v
          })
        }
      })

      this.isReaded = true
    },
    enterQr () {
      const qr = this.productQr.toUpperCase().trim()
      const webUrl = this.storeSetting.webUrl.toUpperCase()
      const isStoreUrl = qr.includes(`${webUrl}/`)
      const storeUrl = isStoreUrl ? `${webUrl}/` : `${webUrl}/`.replace('WWW.', '')
      const codes = qr.split(storeUrl)
      const code = codes[codes.length - 1]
      let isFound = false

      for (let pInd = 0; pInd < this.productsSelected.length; pInd++) {
        const index = this.productsSelected[pInd].skus.findIndex((sku) => sku.code.toUpperCase() === code)
        if (index !== -1) {
          this.productsSelected[pInd].skus[index].count = Number(this.productsSelected[pInd].skus[index].count) + 1
          isFound = true
        }
      }

      if (!isFound) {
        this.setSnackbar({
          value: true,
          message: `${code} not found.`,
          type: 'error'
        })
      }

      this.productQr = ''
    },
    removeListener () {
      window.removeEventListener('onbeforeunload')
      window.removeEventListener('onbeforeprint')
    },
    findWarehouse (id) {
      const warehouse = this.warehouse.find((r) => r.id === id)
      return {
        id: warehouse.id,
        name: warehouse.name,
        code: warehouse.code
      }
    },
    inputValidate () {
      this.validateForm()
    },
    async getSkusAliasByCodes (codes) {
      try {
        this.setLoading({ active: true })

        const promises = await Promise.all(codes.map(async (code) => {
          const { data } = await ProductService.getSkuAliasIdByCode(code)

          return data
        }))

        this.aliasIds = promises.reduce((obj, v) => {
          const tmpObj = { ...obj }
          const code = Object.keys(v)[0]
          const aliasId = v[code]

          tmpObj[aliasId] = code.toUpperCase()

          return tmpObj
        }, {})
      } catch (error) {
        console.error('getSkusAliasByCodes', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      }
    },
    async onConfirmCsv () {
      try {
        this.setLoading({ active: true })

        const { products, skus } = await this.getProductByCodes(this.validData.map((v) => v.sku))
        const selected = []

        this.validData.forEach((v, i) => {
          const sku = skus[v.sku] || null

          if (sku) {
            const product = products[sku.productId]

            selected.push({
              id: product.id,
              name: product.name,
              model: product.model,
              brand: product.brand,
              variant: sku.variant,
              photoUrls: product.photoUrls,
              skus: [
                {
                  ...sku,
                  amount: Number(v.qty)
                }
              ]
            })
          } else {
            this.invalidData.push({
              ...v,
              row: i + 2
            })
          }
        })

        if (!this.invalidData.length) {
          this.productsSelected = []
          selected.forEach((v) => this.productSelecting(v))

          this.showImport = false
          this.isReaded = false
          this.validData = []
          this.invalidData = []
        }
      } catch (error) {
        console.error('onConfirmCsv', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    async getProductByCodes (codes) {
      try {
        this.setLoading({ active: true })

        const { data } = await ProductService.getManyProductByCode({ codes })

        if (!data.length) {
          throw Error('Product not found.')
        }

        const products = data.reduce((obj, p) => {
          const tmpObj = { ...obj }
          tmpObj[p.id] = p

          return tmpObj
        }, {})
        const skus = data.reduce((obj, p) => {
          const tmpObj = { ...obj }

          p.skus.forEach((v) => {
            tmpObj[v.code] = v
          })

          return tmpObj
        }, {})

        return { products, skus }
      } catch (error) {
        console.error('getProductByCodes', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })

        return null
      } finally {
        this.setLoading({ active: false })
      }
    },
    productSelecting (event) {
      const product = JSON.parse(JSON.stringify({
        ...event,
        photoUrl: event.photoUrl || event.photoUrls[0]
      }))
      const duplicateIndex = this.productsSelected.findIndex((r) => r.id === product.id)
      if (duplicateIndex !== -1 && this.productsSelected[duplicateIndex].id) {
        const duplicateProduct = this.productsSelected[duplicateIndex]
        for (let index = 0; index < product.skus.length; index++) {
          const sku = product.skus[index]
          const skuIndex = duplicateProduct.skus.findIndex((r) => r.id === sku.id)
          if (skuIndex !== -1 && duplicateProduct.skus[skuIndex].id) {
            duplicateProduct.skus[skuIndex].amount++
          } else {
            sku.color = sku.color.name || ''
            sku.size = sku.size.name || ''
            sku.amount = sku?.amount || 1
            sku.count = 0
            duplicateProduct.skus.push(sku)
          }
        }
        this.productsSelected.splice(duplicateIndex, 1, duplicateProduct)
        this.stock = this.setProductStock([product], this.stock)
      } else {
        product.skus = product.skus.map((sku) => ({
          ...sku,
          color: sku.color.name || '',
          size: sku.size.name || '',
          amount: sku?.amount || 1,
          count: 0
        }))
        this.stock = this.setProductStock([product], this.stock)
        this.productsSelected.push(product)
      }
    },
    validateForm () {
      clearTimeout(this.validateFormTimeout)
      this.validateFormTimeout = setTimeout(() => {
        this.valid = this.$refs.form.validate()
      }, 50)
    },
    setCount () {
      for (let i = 0; i < this.productsSelected.length; i++) {
        const product = this.productsSelected[i]
        for (let j = 0; j < product.skus.length; j++) {
          const sku = product.skus[j]
          sku.count = sku.amount
        }
      }
    },
    removeProduct (index) {
      this.productsSelected.splice(index, 1)
    },
    removeSku ({ productIndex, skuIndex }) {
      if (this.productsSelected[productIndex].skus.length > 1) {
        this.productsSelected[productIndex].skus.splice(skuIndex, 1)
      } else {
        this.removeProduct(productIndex)
      }
    },
    async submitNote (status) {
      try {
        this.loading = true
        if (this.isTransferNote && !status) {
          const note = await this.createTransferNote()
          if (this.noteId) {
            this.setNoteData(note)
          } else {
            this.$router.push({
              name: this.$route.name,
              query: {
                id: note.id,
                warehouse: this.warehouseId
              }
            })

            this.initials()
          }
        } else if (this.noteId && status) {
          const note = await this.updateNote(status)
          this.setNoteData(note)
        } else if (this.noteId) {
          const note = await this.updatePendingNote()
          this.setNoteData(note)
        } else {
          const note = await this.createNote(status)
          if (this.stockAdjustmentNoteId) {
            const skusInNote = this.productsSelected.flatMap((product) => product.skus.map((sku) => sku.id))
            await this.updateNoteInStockAdjustment({
              noteId: note.id,
              noteCode: note.code,
              stockCountAdjustmentId: this.stockAdjustmentNote.stockCountAdjustment,
              stockAdjustmentNoteId: this.stockAdjustmentNoteId,
              noteType: 'wn',
              skusInNote
            })
          }
          this.$router.push({
            name: this.$route.name,
            query: {
              id: note.id,
              warehouse: this.warehouseId
            }
          })

          this.initials()
        }
      } catch (error) {
        console.error('submitNote', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async renewNote () {
      try {
        this.loading = true
        const note = await this.createNote('draft')
        this.$router.push({
          name: this.$route.name,
          query: {
            id: note.id,
            warehouse: this.warehouseId
          }
        })

        this.initials()
      } catch (error) {
        console.error('submitNote', error)
      } finally {
        this.loading = false
      }
    },
    // Real Create Note MTFK.
    async createNote (status, ref = null) {
      if (this.reason === 'UNDO_RECEIVED') {
        const { data } = await InventoryService.undoReceiveDeliveryNote(this.noteRef.code, {
          reason: this.reason,
          warehouse: this.warehouseSelected,
          products: this.productsSelected,
          note: this.note,
          deliveryNoteRef: null,
          status: status || 'pending'
        })

        return data
      }

      const payload = {
        reason: this.reason,
        warehouse: this.warehouseSelected,
        products: this.productsSelected,
        note: this.note,
        deliveryNoteRef: ref,
        status: status || 'pending'
      }
      if (this.stockAdjustmentNoteId) {
        payload['stockCountAdjustmentNoteRef'] = {
          stockCountAdjustmentId: this.stockAdjustmentNote.stockCountAdjustment,
          stockCountAdjustmentNoteId: this.stockAdjustmentNoteId
        }
      }
      const { data } = await InventoryService.createWithdrawNotes(payload)

      return data
    },
    async createDeliveryNote (status, ref = {}) {
      const { data } = await InventoryService.createDeliveryNotes({
        reason: this.reason,
        warehouse: this.transferWarehouseSelected,
        products: this.productsSelected,
        note: this.note,
        withdrawNoteRef: ref,
        status: status || 'pending'
      })
      return data
    },
    async createTransferNote () {
      let withdrawNote = null
      if (this.reason === 'STOCK_COUNT' || this.reason === 'DEFECT' || this.reason === 'UNDO_RECEIVED' || this.reason === 'SAMPLE') {
        withdrawNote = await this.createNote('pending')

        return withdrawNote
      }

      withdrawNote = await this.createNote('draft')
      const deliveryNote = await this.createDeliveryNote('pending', {
        id: withdrawNote.id,
        code: withdrawNote.code,
        warehouseId: +this.warehouseSelected.id
      })
      withdrawNote = await this.updatePendingNote(withdrawNote.id, {
        id: deliveryNote.id,
        code: deliveryNote.code,
        warehouseId: +deliveryNote.warehouse.id
      })
      return withdrawNote
    },
    async updateNote (status) {
      const { data } = await InventoryService.updateWithdrawNotes(this.noteId, {
        reason: this.reason,
        warehouse: this.warehouseSelected,
        products: this.productsSelected,
        note: this.note,
        status: status || 'pending'
      })
      return data
    },
    async updatePendingNote (id = this.noteId, ref = {}) {
      const { data } = await InventoryService.updatePendingWithdrawNotes(id, {
        reason: this.reason,
        warehouse: this.warehouseSelected,
        products: this.productsSelected,
        note: this.note,
        deliveryNoteRef: ref,
        status: 'pending'
      })
      return data
    },
    async tryToUpdateNote (status) {
      if (status === 'cancel') {
        this.setModal({
          value: true,
          title: 'ยืนยันการยกเลิก',
          message: 'การยกเลิกใบนำสินค้าออกจะไปยกเลิกใบนำสินค้าเข้าด้วย โปรดตรวจสอบให้ถูกต้องก่อนการกดยกเลิก (ประวัติของคุณจะถูกเก็บไว้ในระบบ)',
          confirmText: 'ยืนยันการยกเลิก',
          confirmType: 'error',
          cancelType: '',
          cancelText: 'ไม่ทำรายการ',
          onConfirm: () => this.updateStatusNote(status)
        })
      } else {
        this.updateStatusNote(status)
      }
    },
    async updateStatusNote (status) {
      try {
        this.loading = true
        const { data } = await InventoryService.updateStatusWithdrawNotes({
          id: this.noteId,
          status
        }, {
          warehouse: this.warehouseSelected,
          products: this.productsSelected
        })
        this.setNoteData(data)
      } catch (error) {
        console.error('updateStatusNote', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async fetchNote () {
      try {
        this.loading = true
        this.setLoading({ active: true })
        const { data } = await InventoryService.getWithdrawNoteById(this.noteId)
        const codes = data.products.reduce((arr, v) => {
          const skuCodes = v.skus.map((s) => s.code)

          return [...arr, ...skuCodes]
        }, [])

        await this.getSkusAliasByCodes(codes)
        await this.setNoteData(data)
        if (this.isDraft || this.isPending) {
          const products = await this.getManyProductStock()
          this.stock = this.setProductStock(products)
        }
      } catch (error) {
        console.error('fetchNote', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
        this.setLoading({ active: false })
        this.validateForm()
      }
    },
    async getDeliveryNote () {
      try {
        this.loading = true
        const { data } = await InventoryService.getDeliveryNoteById(this.deliveryNoteId)
        const codes = data.products.reduce((arr, v) => {
          const skuCodes = v.skus.map((s) => s.code)

          return [...arr, ...skuCodes]
        }, [])

        await this.getSkusAliasByCodes(codes)
        await this.setNoteData({
          ...data,
          code: '',
          status: 'draft',
          deliveryNoteRef: {
            id: data.id,
            code: data.code,
            warehouseId: data.warehouse.id
          }
        })

        this.reason = 'UNDO_RECEIVED'
      } catch (error) {
        console.error('getDeliveryNote', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
        this.validateForm()
      }
    },
    async setNoteData (data) {
      const products = data.products.map((product) => ({
        ...product,
        skus: this.sortSize(product.skus)
      }))
      this.status = data.status
      this.reason = data.reason || '-'

      if (data.id) {
        this.qr = await generateQRCode(`${process.env.VUE_APP_URL}/notes/withdraw-note?id=${data.id}&warehouse=${this.noteWarehouse}`)
      }

      this.noteCode = data.code
      this.createBy = data.createdBy.email
      this.updateBy = data.updatedBy.email
      this.updateTime = new Date(data.approvedDate).toString()
      this.note = data.note
      this.noteRef = data.deliveryNoteRef
      this.noteWarehouse = data.warehouse.id || 0
      this.productsSelected = []
      this.productsSelected.push(...products)
      this.transferWarehouseId = this.mapRef(data)
    },
    mapRef (data) {
      if (data.deliveryNoteRef && data.deliveryNoteRef.warehouseId) {
        return data.deliveryNoteRef.warehouseId
      }
      return null
    },
    print () {
      this.printTime = new Date().toString()
      setTimeout(() => {
        window.print()
      }, 100)
    },
    async handleAfterPrint () {
      try {
        const res = await InventoryService.updatePrintedWithdrawNote(this.noteId, { print: true })
        if (res.data) {
          this.setSnackbar({
            value: true,
            message: 'Printed successfully',
            type: 'success'
          })
        }
      } catch (error) {
        console.error('handleAfterPrint', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      }
    },
    async getManyProductStock () {
      const { data } = await ProductService.getManyProductById({
        productIds: this.productsSelected.map((r) => r.id)
      })
      return data
    },
    setProductStock (products, array = []) {
      const stock = JSON.parse(JSON.stringify(array))
      for (let index = 0; index < products.length; index++) {
        const product = products[index]
        for (let j = 0; j < product.skus.length; j++) {
          const sku = product.skus[j]
          stock.push({
            id: sku.id,
            stock: sku?.stock?.find((r) => r.warehouse.id === this.warehouseId) ?? null
          })
        }
      }
      return stock
    },
    approve () {
      this.valid = this.$refs.form.validate()
      if (!this.valid) {
        return
      }

      let isAlert = false
      for (let pInd = 0; pInd < this.productsSelected.length; pInd++) {
        const index = this.productsSelected[pInd].skus.findIndex((sku) => +sku.count !== +sku.amount)
        if (index !== -1) {
          isAlert = true
          break
        }
      }
      if (isAlert) {
        this.$store.dispatch('Components/setModal', {
          value: true,
          title: 'แจ้งเตือน !!',
          message: 'สินค้าที่รับเข้าไม่ตรงกับเอกสารจะยืนยันหรือไม่ ?',
          confirmText: 'ยืนยัน',
          confirmType: 'success',
          cancelType: '',
          cancelText: 'ปิด',
          onConfirm: () => this.updateStatusNote('approve')
        })
      } else {
        this.setModal({
          value: true,
          title: 'ยืนยันการย้ายของออก',
          message: 'โปรดนับจำนวนของให้ถูกต้องก่อนย้ายของ',
          confirmText: 'ยืนยัน',
          confirmType: 'success',
          cancelType: '',
          cancelText: 'ปิด',
          onConfirm: () => this.updateStatusNote('approve')
        })
      }
    },
    sortSize (skus) {
      return skus.sort((a, b) => SizeValues(a.size) - SizeValues(b.size))
    },
    async fetchStockAdjustmentNote () {
      try {
        this.loading = true
        const { data: stockAdjustmentNote } = await stockCountAdjustmentService.getStockAdjustmentNote({
          stockAdjustmentNoteId: this.stockAdjustmentNoteId,
          noteType: 'wn'
        })
        this.stockAdjustmentNote = stockAdjustmentNote
        const products = []
        stockAdjustmentNote.products.forEach((p) => {
          const skus = p.skus.map((sku, i) => ({
            ...sku,
            sequence: (i + 1) * 10000
          }))

          products.push({
            id: p.id,
            model: p.model,
            name: p.name,
            photoUrl: p.photoUrls || '',
            variant: p.variant,
            skus
          })
        })

        this.reason = stockAdjustmentNote.reason

        const codes = products.reduce((arr, v) => {
          const skuCodes = v.skus.map((s) => s.code)

          return [...arr, ...skuCodes]
        }, [])

        await this.getSkusAliasByCodes(codes)

        this.productsSelected = products

        if (!this.noteId) {
          this.note = stockAdjustmentNote.note
        }
      } catch (error) {
        console.error('fetchPurchaseOrder', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
        this.validateForm()
      }
    },
     async updateNoteInStockAdjustment ({
      noteId,
      noteCode,
      stockCountAdjustmentId,
      stockAdjustmentNoteId,
      noteType,
      skusInNote
    }) {
      const updated = await stockCountAdjustmentService.updateNoteInStockAdjustment({
        noteId,
        noteCode,
        stockCountAdjustmentId,
        stockAdjustmentNoteId,
        noteType,
        skusInNote
      })
      return updated
    }
  }
}
</script>

<style lang="scss">
#withdrawnote-bar {
  .app-bar-title {
    display: flex;
    align-items: center;
    width: 230mm;
    margin: 0 auto;
    > button {
      margin: 0 5px;
    }
  }
  .checkbox-transfer {
    .v-icon.v-icon {
      font-size: 16px;
    }
    .v-input--selection-controls__ripple {
      width: 24px;
      margin: 0;
      height: 24px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .v-input--selection-controls__input {
      margin-right: 0;
    }
  }
}
.page-a4-title {
  display: flex;
  align-items: center;
}

</style>
